<script setup lang="ts">
import { ICONS } from './icons';
import { Notification } from '@/interfaces';

defineProps<{
  notification: Notification;
}>();

const { t } = useI18n();

const titles = {
  success: t('components.common.notification.success'),
  warning: t('components.common.notification.warning'),
  info: t('components.common.notification.info'),
  error: t('components.common.notification.error'),
};
</script>

<template>
  <div class="notification-instance">
    <component :is="ICONS[notification.type]"></component>
    <div class="container fw500">
      <div class="title">{{ titles[notification.type] }}</div>
      <div class="message">
        <span>{{ notification.message }}</span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.notification-instance {
  @apply flex cursor-pointer gap-3 py-3 px-4 rounded-lg
    border border-solid border-gray-4 bg-white w-[400px] max-w-[90%];

  svg {
    $px: 24px;
    min-width: $px;
    width: $px;
    height: $px;
  }

  .container {
    .title {
      text-transform: capitalize;
      @apply mb-2 text-sm;
    }

    .message {
      @apply opacity-60 text-xs;
    }
  }
}
</style>
