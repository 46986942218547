<script setup lang="ts">
import { Notification } from '@/interfaces';
import { useNotificationStore } from '@/stores/notification';

import Instance from './Instance.vue';

const notificationStore = useNotificationStore();

const state = reactive({
  notifications: [] as Array<Notification>,
});

const destroyInstance = (id: number) => {
  state.notifications = state.notifications.filter((notification) => notification.id !== id);
};

const generateInstance = (notification: Notification) => {
  const notif = {
    id: Math.floor(Math.random() * 10001),
    ...(notification as Notification),
  };
  state.notifications = [...state.notifications, notif];
  setTimeout(() => {
    destroyInstance(notif.id);
  }, notif.timeout || 5000);
};

notificationStore.$subscribe((_mutation, state) => {
  if (state.notification) generateInstance(state.notification);
});
</script>

<template>
  <section class="notifications flex-col">
    <transition-group name="notification">
      <instance
        v-for="(notification, index) of state.notifications"
        :key="index"
        :notification="notification"
        @click="destroyInstance(notification.id as number)"
      ></instance>
    </transition-group>
  </section>
</template>

<style scoped lang="scss">
.notifications {
  @apply fixed top-4 right-4 gap-3 z-40 items-end;
}
</style>
